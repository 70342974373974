<template>
    <b-card class="center_card" footer-class="my-footer">
      <b-card-title class="title-card-mdp mb-2">Réinitialisation de votre mot de passe</b-card-title>
      <h4 class="sub-title mb-3" style="font-size: 15px">Pour réinitialiser votre mot de passe, merci de renseigner l'email associé à votre compte Applicasol.</h4>
      <b-row no-gutters>
        <b-col>
              <b-form-group
                  class="mt-2"
                  label-class="font-weight-bold"
                  label-size="lg"
                  label-align="left">
                <b-input-group >
                    <b-input-group-text variant="primary">
                      <i class="fas fa-user"></i>
                    </b-input-group-text>
                  <Field name="email" type="email" placeholder="Email" class="form-control" v-model="email" />
                </b-input-group>
                <ErrorMessage name="email" class="text-danger" />
              </b-form-group>
              <b-form-group
                  class="mt-3 mb-3"
                  label-class="font-weight-bold"
                  label-size="lg"
                  label-align="left">
                <b-input-group >
                    <b-input-group-text variant="primary">
                      <i class="fas fa-lock"></i>
                    </b-input-group-text>
                  <Field name="password" :type="showPassword ? 'text' : 'password'" placeholder="Mot de passe" minlength="8" required class="form-control" v-model="password" />
                  <b-input-group-text class="cursor-pointer" @click.prevent="toggleShowPassword">
                    <img v-if="showPassword" src="@/assets/eye-slash-solid.svg" alt="Masquer" width="16" height="16">
                    <img v-else src="@/assets/eye-solid.svg" alt="Afficher" width="16" height="16">
                  </b-input-group-text>
                  <ErrorMessage name="password" class="text-danger" />
                </b-input-group>

              </b-form-group>

              <b-form-group
                  class="mt-3 mb-3"
                  label-class="font-weight-bold"
                  label-size="lg"
                  label-align="left">
                <b-input-group>
                  <b-input-group-text variant="primary">
                    <i class="fas fa-lock"></i>
                  </b-input-group-text>
                  <Field name="mdpConfirm" :type="showConfirmPassword ? 'text' : 'password'" placeholder="Confirmer votre mot de passe" minlength="8" required class="form-control" v-model="mdpConfirm" />
                  <b-input-group-text class="cursor-pointer" @click.prevent="toggleShowConfirmPassword">
                    <img v-if="showConfirmPassword" src="@/assets/eye-slash-solid.svg" alt="Masquer" width="16" height="16">
                    <img v-else src="@/assets/eye-solid.svg" alt="Afficher" width="16" height="16">
                  </b-input-group-text>
                </b-input-group>
                <ErrorMessage name="mdpConfirm" class="text-danger" />
              </b-form-group>

        </b-col>
      </b-row>
      <div class="valider">
        <button pill @click = "reinitialiseMdp" class="btn-valid m-2">Valider</button>
      </div>
      <template #footer>
        <div class="p-2">
          <span class="footer-card">Copyright © 2009-{{new Date().getFullYear()}} INRAE, Tous droits réservés</span>
        </div>
      </template>
    </b-card>
</template>

<script>
import http from '@/http.js'
import { ref } from 'vue';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import router from "@/router";

export default {
  name: 'ResetPassword',
  components:{
    Field,
    ErrorMessage,
  },

  props:{
    token:{
      required:true,
      type:String
    },
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props) {
    const email = ref('');
    const password = ref('');
    const mdpConfirm = ref('');
    const showPassword = ref(false);
    const showConfirmPassword = ref(false);

    const schema = yup.object({
      email: yup.string().email('Le format de l\'email saisi est incorrect').required('Ce champ est obligatoire'),
      password: yup.string().min(12, 'Le mot de passe doit contenir au moins 12 caractères').required('Ce champ est obligatoire').matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?,£;.:!@$%&^*-]).*$/, 'Le mot de passe doit contenir au moins: une minuscule, une majuscule, un chiffre et un caractère spécial.'),
      mdpConfirm: yup.string().oneOf([yup.ref('password')], 'Les mots de passe ne correspondent pas').required('Ce champ est obligatoire'),
    });

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const toggleShowPassword = () => {
      showPassword.value = !showPassword.value;
    };

    const toggleShowConfirmPassword = () => {
      showConfirmPassword.value = !showConfirmPassword.value;
    };

    const reinitialiseMdp = handleSubmit(async () => {
      try {
        const userExists = await http.verifExistUserByEmail(email.value);
        if (userExists) {
          const reinitmdpData = {
            token: props.token,
            email: email.value,
            mdp: password.value,
          };
          const response = await http.reinitialiseMdp(reinitmdpData);
          if (response === 'ok') {
            await router.push({ name: 'Login' });
          }
        }
      } catch (error) {
        console.error('Erreur lors de la réinitialisation du mot de passe:', error);
      }
    });

    return {
      email,
      password,
      mdpConfirm,
      showPassword,
      showConfirmPassword,
      toggleShowPassword,
      toggleShowConfirmPassword,
      reinitialiseMdp,
    };
  },
};
</script>

<style scoped>

.error{
  color:  #f0c1c0;
  font-weight: bold;
}


</style>
